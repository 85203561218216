import logo from "./mehi.gif";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          <code>ag1.mehilainen.fi</code> ja <code>login1.mehilainen.fi</code>{" "}
          portaalit ovat poistettu käytöstä. Uusi portaali on osoitteessa{" "}
          <a
            className="App-link"
            href="https://mehilainen.cloud.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://mehilainen.cloud.com
          </a>
        </p>
        <p>
          Mehiläisen työasemien työpöydältä löytyy “Citrix” niminen pikakuvake,
          josta avautuu{" "}
          <a
            className="App-link"
            href="https://mehilainen.cloud.com "
            target="_blank"
            rel="noopener noreferrer"
          >
            https://mehilainen.cloud.com
          </a>{" "}
          portaali
        </p>
      </header>
    </div>
  );
}

export default App;
